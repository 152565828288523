/**
 * About us EN meta
 */
const meta = {
  keywords: "Money transfer, cameroon no fees",
  meta_description_default:
    "Send money for free from Canada to Cameroon. Quick and safe without any fees using the REAL exchange rate of the day. Mobile money and Bank account deposit supported.",
  meta_description_register:
    "Sign up on Transfergratis and start sending money online in minutes•! Create your account now to get started. Fast, Free, Secure!",
  meta_description_login:
    "Log in to your Transfergratis account and send money to Cameroon without fees. Fast and secure!",
  meta_description_secure:
    "Transfergratis is regulated and authorized to carry out money transfers by the Canadian federal government and the provincial government of Quebec.",
  meta_description_faqs:
    "Need help? Find answers to the most frequently asked questions.",
  meta_description_contact_us: "Here’s how to contact Transfergratis 24/24 7/7",
  meta_description_terms: "Terms and Conditions of Transfergratis",
  meta_description_privacy:
    "This Privacy Policy describes how Transfergratis uses, stores and manages certain informations that you, as a user of our services, provide to us.",
  meta_description_send:
    "Transfer money with Transfergratis for FREE to your loved ones in Cameroon in minutes.",
  meta_description_cookies:
    "This Privacy Policy describes how Transfergratis uses, stores and manages certain informations that you, as a user of our services, provide to us.",
  meta_description_transfer:
    "Transfer money with Transfergratis for FREE to your loved ones in {{country}} in minutes.",
  titlesecure: "Security at Transfergratis",

  titleterms: "Terms and Conditions | Transfergratis",
  titleprivacy: " Privacy Policy | Transfergratis",
  ssss: "ssssss",
  title: {
    default: "Send money whitout fees to Cameroon",
    about_us: "About us",
    cid: "KYC",
    contact_us: "Contact us",
    dashboard: {
      profile: "Profile",
      security: "Security",
      transaction: "My transactions",
    },
    faqs: "Frequently Asked Questions",
    login:
      "Login: Log in to your Transfergratis account and send money to Cameroon and other countries without fees. Fast and secure!",
    register:
      "Register: Sign up on Transfergratis and start sending money online in minutes•! Create your account now to get started. Fast, Free, Secure!",
    resetPassword: "Reset password",
    transfer:
      "Send Money : Send money with Transfergratis for FREE to your loved ones in Cameroon in minutes.",
    career: "Career : We are looking for a new challenger",
    terms: "Terms and Conditions | Transfergratis",
    cookies: "Cookies | Transfergratis",
    policy: "Privacy Policy | Transfergratis",
    transferTitle: "Send Money ",
  },
};

export default meta;
