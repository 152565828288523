/**
 * About us FR meta
 */
const meta = {
  keywords: "Transfert d'argent, cameroun sans frais",
  meta_description_default:
    "Envoyez de l'argent gratuitement du Canada au Cameroun. Rapide et sûr sans aucun frais en utilisant le taux de change RÉEL du jour. L'argent mobile et le dépôt sur compte bancaire sont pris en charge.",
  meta_description_register:
    "Inscrivez-vous sur Transfergratis et commencez à envoyer de l'argent en ligne en quelques minutes! Créez votre compte dès maintenant pour commencer. Rapide, Gratuit, sécurisé!",
  meta_description_login:
    "Connectez-vous à votre compte Transfergratis et envoyez de l'argent vers le Cameroun Sans frais. Rapide et sécurisé!",
  meta_description_secure:
    "Transfergratis est réglementé et autorisé à effectuer les transferts d'argent par le gouvernement fédéral canadien et le gouvernement provincial du Québec.",
  meta_description_faqs:
    "Besoin d'aide? Retrouvez des réponses aux questions les plus fréquemment posées.",
  meta_description_contact_us:
    "Voici comment contacter Transfergratis 24/24 7/7",
  meta_description_terms: "Termes et conditions de Transfergratis",
  meta_description_privacy:
    "Cette politique de confidentialité décrit la façon dont Transfergratis utilise, stocke, gère et dispose de certaines informations que vous, en tant qu'utilisateur de nos services, nous fournissez.",
  meta_description_send:
    "Avec Transfergratis, tranfert de l’argent GRATUITEMENT à tes proches au Cameroun en quelques minutes.",
  meta_description_cookies:
    "Cette politique de confidentialité décrit la façon dont Transfergratis utilise, stocke, gère et dispose de certaines informations que vous, en tant qu'utilisateur de nos services, nous fournissez.",

  meta_description_transfer:
    "Avec Transfergratis, tranfert de l’argent GRATUITEMENT à tes proches au {{country}} en quelques minutes.",
  titlesecure: "La sécurité chez Transfergratis",

  titleterms: "Termes et conditions | Transfergratis",
  titleprivacy: "Politique de confidentialité | Transfergratis",

  sssss: "ssssssss",
  title: {
    about_us: "A propos de nous",
    cid: "KYC",
    contact_us: "Contactez-nous : Comment contacter Transfergratis 24/24 7/7",
    dashboard: {
      profile: "Profil",
      security: "Securité",
      transaction: "Mes transactions",
    },
    default: "Envoyer de l'argent au Cameroun sans frais",
    faqs: "Foire aux questions",
    login:
      "Se connecter : Connectez-vous à votre compte Transfergratis et envoyez de l'argent vers le Cameroun et autres pays Sans frais. Rapide et sécurisé!",
    register:
      "S'inscrire : Créez votre compte dès maintenant pour commencer. Rapide, Gratuit, Sécurisé!",
    resetPassword: "Réinitialiser le mot de passe",
    transfer: "Envoyer de l’argent",
    career: "Carrière : Nous recherchons un nouveau challenger",
    terms: "Conditions et conditions d'utilisation",
    cookies: "Cookies  ",
    policy: "Politique de confidentialité ",
    transferTitle: "Envoyer de l’argent",
  },
};

export default meta;
