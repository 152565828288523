/**
 * global EN translations
 */
const globalLang = {
  address: "Address",
  account: "Account",
  amount: "Amount",
  answer: "Answer",
  authorize: "authorize",
  balance: "Balance",
  canada: "Canada",
  cancel: "Cancel",
  change: "Change",
  city: "City",
  close: "close",
  region: "Region",
  confirm: "Confirm",
  contactUs: "Contact us",
  cookies: "Cookies and Tracking Technologies Policy",
  country: "Country",
  dashboard: "Dashboard",
  description: "Description",
  message: "Your message",
  deposit: "Deposit",
  sendForm: "Send Form",
  disbursable: "Disbursable",
  dob: "Date of birth",
  edit: "Edit",
  email: "Email",
  emailPlaceholder: "Entrer email Address",
  england: "England",
  error: "Error",
  errorLogin: "Incorrect email/password combination",
  exchangeRate: "The current exchange rate",
  forgetPwd: "Forgot Password",
  fname: "First name",
  friend: "Friends",
  gender: "Gender",
  howHear: "How did you hear about us?",
  help: "FAQ",
  history: "History",
  invalid: "Invalid",
  key: "Key",
  contry: "Contry",
  lname: "Last name",
  flname: "Full Name",
  logout: "Logout",
  locked: "Locked",
  man: "Man",
  mname: "Middle name",
  name: "Name",
  of: "of",
  occupation: "Occupation",
  optional: "Optional",
  password: "Password",
  pay: "Pay",
  pending: "In progress",
  phone: "Phone",
  postalCode: "Postal code",
  previous: "Previous",
  privacy: "Privacy",
  province: "Province",
  question: "Question",
  register: "Register",
  recipient: "Recipient",
  recipientReceive: "The recipient receives",
  security: "security",
  save: "Save",
  send: "Send for free",
  select: "Select",
  share: "Share",
  signIn: "Sign In",
  signUp: "Sign Up",
  subject: "Subject",
  successful: "Successful",
  totalFee: "Total fee",
  withdrawalFee: "Withdrawal fees",
  town: "Town",
  welcome: "welcome",
  withdrawalMethod: "Withdrawal method",
  woman: "Woman",
  youSend: "You send",
  terms: "Terms and conditions",
  sponsorship: "Sponsorship code",

  motivation: "Motivation Letter",
  submit: "Submit",
  postulate: "postulate",
  contract_type: "Contract type",
  work_type: "Work type",
  posted_at: "Posted at",
  location: "Location",
  category: "Category",
  job_offer: "Job Offer",
  test_link: "Test link",

  recus: "Receipts",
  networkSignalIssue:
    "The {{network}} network signal is experiencing disruptions, so if you continue, your transaction may take longer to complete.",

  captchaError: "CAPTCHA error. Please check again.",
  errorCarrier: "Error from Carrier",
  infoSecure: "Your information is safe with us",
  noReceiver: "{{operator}} account non-existent",
  noAccount: "No account?",
  recipientLimit: "Recipient limit exceeded",
  welcomeMessage: "We are happy to see you again",
  wrongName: "Wrong Name",

  all: "all recipients",

  careers: "Career",

  requiedFName: "Required",
  requiedEmail: "The Email field is Required",
  requiedCity: "Required",
  requiedMessage: "Required",
  requiedContry: "Required",
  other: "Other",
  passwordSizeError: "Incorrect password size: minimum 8 characters.",
};

export default globalLang;
