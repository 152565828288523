const link = {
  send: "send",
  contactUs: "contact-us",
  faqs: "faqs",
  aboutUs: "about-us",
  resetPassword: "resetPassword",
  resetPasswords: "resetPasswords",
  login: "login",
  register: "register",
  transferInit: "transfer/init",
  connectMX: "connectMX",
  conditionsTerms: "conditions-terms",
  policy: "policy",
  cookies: "cookies",
  career: "career",
  careerJobOffer: "career/jobOffer",
};

export default link;
